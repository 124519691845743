



























































import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'Instructions'
})
export default class Instructions extends Vue {
  mounted() {
    // 获取当前语言
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }

  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language || 'ug'
  }
}
